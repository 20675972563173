import React from 'react';

const useScript = (
  scriptUrl: string,
  scriptId: string,
) => {
  React.useEffect(() => {
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.id = scriptId;
      document.body.appendChild(script);
    }

    return () => {
      if (existingScript ) {
        existingScript.remove();
      }
    };
  }, [scriptUrl, scriptId]);
};

export default useScript;