import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

function EmbeddedPost(props: any) {
    let { post } = props;

    useEffect(() => {
        FB.api(
            `/oembed_post?url=${post.permalink_url}`,
            function (response: any) {
              console.log(response);
            }
        );
    }, [post]);

    let date = new Date(post.created_time);

    return <Card className="fb-post">
        {post.full_picture &&
            <Card.Img variant="top" src={post.full_picture} />
        }
        <Card.Body>
            <Card.Subtitle className="mb-2 text-muted">{date.toLocaleDateString()} - {date.toLocaleTimeString()}</Card.Subtitle>
            <Card.Text>
                {post.message}
            </Card.Text>
        </Card.Body>
    </Card>
}

export default EmbeddedPost;
