import React from 'react';
import '../resources/css/App.css';

const classNames = require('classnames');


function FriendDetails(props: any) {

    const { friend } = props;

    // const profilePictureInterface = {
    //     url: ""
    // }

    //let [friendDetails, setFriendDetails] = useState([]);
    //let [profilePicture, setProfilePicture] = useState(profilePictureInterface);

    let elClass = classNames({
        'friend': true,
        'checked': friend.checked
    });

    return (
        <p className={elClass} onClick={()=> props.markChecked(friend)}>
            {/* {profilePicture &&
                <img src={profilePicture.url} />
            } */}
            {friend.name}
        </p>
    );
}

export default FriendDetails;
