import React, { useEffect, useState } from 'react';
import '../resources/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useScript from '../hooks/useScript';
import FriendDetails from './FriendDetails';
import EmbeddedPost from '../components/EmbeddedPost';
import { Col, Container, Row } from 'react-bootstrap';

interface FbFriend {
  id: string;
  name: string;
  checked: boolean;
}

function App() {

  const loadedFbSdk = useScript(
    "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2",
    "facebookSdk",
  );

  let [friends, setFriends] = useState<FbFriend[]>([]);
  let [isAuth, setIsAuth] = useState(false);
  let [feedPosts, setFeedPosts] = useState<any>([]);
  let [fbAccessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '322812415681478',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0'
      });
    };
  }, [loadedFbSdk]);

  const retrieveUserPosts = (friend: any) => {
    return new Promise(resolve => {
      FB.api(`/${friend.id}/posts?fields=id,full_picture,message,permalink_url,created_time`, (response: any) => {
        resolve(response.data);
      })
    }).then((a) => {
      return a;
    })
  }

  const loginWithFb = () => {
    FB.login(function (response: fb.StatusResponse) {
      if (response.authResponse) {
        setIsAuth(true);
        getFriends();
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'groups_access_member_info,email,user_friends,user_posts' });
  }

  const loadPosts = () => {
    let posts: any[] = [];
    friends.forEach(friend => {
      if (friend.checked) {
        posts.push(retrieveUserPosts(friend));
      }
    });
    return Promise.all(posts).then((responses) => {
      let mergedResults: any = [];
      responses.forEach((response) => {
        mergedResults.push(...response);
      })
      return mergedResults.sort((a: any, b: any) => (a.created_time <= b.created_time) ? 1 : -1);
    }).then((data) => {
      return data;
    });
  }

  const renderFeed = async () => {
    let posts = await (loadPosts());
    setFeedPosts(posts);
  }

  // const renderFeedEmbed = async () => {
  //   FB.api(
  //     "/oembed_post?url=P",
  //     function (response: any) {
  //       if (response && !response.error) {
  //         /* handle the result */
  //       }
  //     }
  //   );
  // }
  // const getPostDetails = (post: any) => {
  //   console.log(post);
  // }

  const getFriends = () => {
    getSelectedFriends();
    FB.api('/me/friends', function (response: any) {
      setFriends(response.data);
    });
  }

  const getSelectedFriends = () => {
    FB.getLoginStatus((data) => {
      setAccessToken(data.authResponse.accessToken);
      fetch(`https://fbserver.netlify.app/.netlify/functions/user-read/?accessToken=${data.authResponse.accessToken}`).then((data) => {
        console.log(data);
        console.log(fbAccessToken);
      })
    });
  }

  const markChecked = (friend: any) => {
    let updatedFriends = JSON.parse(JSON.stringify(friends));
    const el = updatedFriends.find((el: any) => el.id === friend.id);
    if (el) {
      el.checked = (typeof el.checked !== "undefined") ? !el.checked : true;
    }
    setFriends(updatedFriends);
  }

  return (
    <Container fluid style={{ maxWidth: "1024px" }}>
      <h1>Facebook Declutter</h1>
      <p><a href="https://m.facebook.com/notifications" target="_blank" rel="noopener noreferrer">View notifications</a></p>
      <p><a href="https://m.facebook.com/groups/Duster.Trails" target="_blank" rel="noopener noreferrer">Duster Trails</a></p>
      {!isAuth &&
        <button
          onClick={loginWithFb}
        >
          Login
          </button>
      }

      {isAuth &&
        <>
          <button onClick={renderFeed}>Load Posts</button>
          <Row>
            <Col md="5" className="friendsList">
              {friends.length > 0 &&
                friends.map((friend: any) => {
                  return <FriendDetails key={friend.id} friend={friend} markChecked={markChecked} />
                })
              }
            </Col>
            <Col md="7" className="feed">
              {
                feedPosts.map((post: any) => {
                  return <EmbeddedPost key={post.id} post={post} />
                })
              }
            </Col>
          </Row>
        </>
      }
    </Container>
  );
}

export default App;
